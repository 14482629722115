@font-face {
  font-family: icons;
  src: url("icons.c3a012aa.eot");
  src: url("icons.c3a012aa.eot#iefix") format("embedded-opentype"), url("icons.71ce8288.woff") format("woff"), url("icons.4fd85b9d.ttf") format("truetype"), url("icons.735d85e3.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: icons !important;
  font-style: normal !important;
  font-weight: normal !important;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: icons !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.icon-add-plus-circle-icon:before {
  content: "a";
}

.icon-add-reporter-icon:before {
  content: "b";
}

.icon-arrow-drop-right-icon:before {
  content: "c";
}

.icon-attach-images-icon:before {
  content: "d";
}

.icon-bar-chart-2-icon:before {
  content: "e";
}

.icon-bar-chart-icon:before {
  content: "f";
}

.icon-bug-icon:before {
  content: "g";
}

.icon-calendar-icon:before {
  content: "h";
}

.icon-camera-icon:before {
  content: "i";
}

.icon-close-icon:before {
  content: "j";
}

.icon-comment-icon:before {
  content: "k";
}

.icon-copy-icon:before {
  content: "l";
}

.icon-dashboard-icon:before {
  content: "m";
}

.icon-edit-icon:before {
  content: "n";
}

.icon-email-icon:before {
  content: "o";
}

.icon-eye-icon:before {
  content: "p";
}

.icon-eye-slash-icon:before {
  content: "q";
}

.icon-file-chart-icon:before {
  content: "r";
}

.icon-filter-line-icon:before {
  content: "s";
}

.icon-gallery-icon:before {
  content: "t";
}

.icon-history-icon:before {
  content: "u";
}

.icon-history-line-icon:before {
  content: "v";
}

.icon-home-icon:before {
  content: "w";
}

.icon-host-plant-icon:before {
  content: "x";
}

.icon-image-line-icon:before {
  content: "y";
}

.icon-location-icon:before {
  content: "z";
}

.icon-logout-icon:before {
  content: "A";
}

.icon-no-requests-icon:before {
  content: "B";
}

.icon-notification-icon:before {
  content: "C";
}

.icon-paper-clip-icon:before {
  content: "D";
}

.icon-preview-icon:before {
  content: "F";
}

.icon-profile-icon:before {
  content: "G";
}

.icon-quicklinks-icon:before {
  content: "H";
}

.icon-report-icon:before {
  content: "I";
}

.icon-request-icon:before {
  content: "J";
}

.icon-resource-icon:before {
  content: "K";
}

.icon-right-arrow-icon2:before {
  content: "L";
}

.icon-right-circle-arrow-icon:before {
  content: "M";
}

.icon-settings-icon:before {
  content: "N";
}

.icon-share-icon:before {
  content: "O";
}

.icon-telephone-icon:before {
  content: "P";
}

.icon-upload-icon:before {
  content: "Q";
}

.icon-down-arrow-icon:before {
  content: "R";
}

.icon-information-line-icon:before {
  content: "S";
}

.icon-right-arrow-icon:before {
  content: "T";
}

.icon-send-icon:before {
  content: "U";
}

.icon-report-icon-1:before {
  content: "V";
}

.icon-camera-line-icon:before {
  content: "W";
}

.icon-menu-dots-icon:before {
  content: "X";
}

.icon-padlock-icon:before {
  content: "Y";
}

.icon-map-pin-fill-icon:before {
  content: "Z";
}

.icon-search-icon:before {
  content: "0";
}

.icon-pestfax-map-icon:before {
  content: "E";
}

.icon-comment-fill-icon:before {
  content: "1";
}

.icon-question-line-icon:before {
  content: "2";
}

.icon-circle-arrow-icon:before {
  content: "3";
}
/*# sourceMappingURL=index.de3973c6.css.map */
