@charset "UTF-8";

@font-face {
  font-family: "icons";
  src:url("fonts/icons.eot");
  src:url("fonts/icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/icons.woff") format("woff"),
    url("fonts/icons.ttf") format("truetype"),
    url("fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-plus-circle-icon:before {
  content: "\61";
}
.icon-add-reporter-icon:before {
  content: "\62";
}
.icon-arrow-drop-right-icon:before {
  content: "\63";
}
.icon-attach-images-icon:before {
  content: "\64";
}
.icon-bar-chart-2-icon:before {
  content: "\65";
}
.icon-bar-chart-icon:before {
  content: "\66";
}
.icon-bug-icon:before {
  content: "\67";
}
.icon-calendar-icon:before {
  content: "\68";
}
.icon-camera-icon:before {
  content: "\69";
}
.icon-close-icon:before {
  content: "\6a";
}
.icon-comment-icon:before {
  content: "\6b";
}
.icon-copy-icon:before {
  content: "\6c";
}
.icon-dashboard-icon:before {
  content: "\6d";
}
.icon-edit-icon:before {
  content: "\6e";
}
.icon-email-icon:before {
  content: "\6f";
}
.icon-eye-icon:before {
  content: "\70";
}
.icon-eye-slash-icon:before {
  content: "\71";
}
.icon-file-chart-icon:before {
  content: "\72";
}
.icon-filter-line-icon:before {
  content: "\73";
}
.icon-gallery-icon:before {
  content: "\74";
}
.icon-history-icon:before {
  content: "\75";
}
.icon-history-line-icon:before {
  content: "\76";
}
.icon-home-icon:before {
  content: "\77";
}
.icon-host-plant-icon:before {
  content: "\78";
}
.icon-image-line-icon:before {
  content: "\79";
}
.icon-location-icon:before {
  content: "\7a";
}
.icon-logout-icon:before {
  content: "\41";
}
.icon-no-requests-icon:before {
  content: "\42";
}
.icon-notification-icon:before {
  content: "\43";
}
.icon-paper-clip-icon:before {
  content: "\44";
}
.icon-preview-icon:before {
  content: "\46";
}
.icon-profile-icon:before {
  content: "\47";
}
.icon-quicklinks-icon:before {
  content: "\48";
}
.icon-report-icon:before {
  content: "\49";
}
.icon-request-icon:before {
  content: "\4a";
}
.icon-resource-icon:before {
  content: "\4b";
}
.icon-right-arrow-icon2:before {
  content: "\4c";
}
.icon-right-circle-arrow-icon:before {
  content: "\4d";
}
.icon-settings-icon:before {
  content: "\4e";
}
.icon-share-icon:before {
  content: "\4f";
}
.icon-telephone-icon:before {
  content: "\50";
}
.icon-upload-icon:before {
  content: "\51";
}
.icon-down-arrow-icon:before {
  content: "\52";
}
.icon-information-line-icon:before {
  content: "\53";
}
.icon-right-arrow-icon:before {
  content: "\54";
}
.icon-send-icon:before {
  content: "\55";
}
.icon-report-icon-1:before {
  content: "\56";
}
.icon-camera-line-icon:before {
  content: "\57";
}
.icon-menu-dots-icon:before {
  content: "\58";
}
.icon-padlock-icon:before {
  content: "\59";
}
.icon-map-pin-fill-icon:before {
  content: "\5a";
}
.icon-search-icon:before {
  content: "\30";
}
.icon-pestfax-map-icon:before {
  content: "\45";
}
.icon-comment-fill-icon:before {
  content: "\31";
}
.icon-question-line-icon:before {
  content: "\32";
}
.icon-circle-arrow-icon:before {
  content: "\33";
}
